

<template>
  <v-container
    fluid
    fill-height
    v-if="!user.hub_id"
  >
    <v-layout
      align-center
      justify-center
    >
      <v-flex shrink>
        <v-alert
          type="error"
          dense
          outlined
          class="pa-2 mb-0"
          style="display: flex; justify-content: center; align-items: center,height:100%"
        >
          {{ $t("no_default_hub_for_user") }}
        </v-alert>
      </v-flex>
    </v-layout>
  </v-container>
  <v-container v-else>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>{{ $t("POS") }}</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>

        <v-row justify="end">
          <v-dialog
            v-model="isVisibleCheckoutDialog"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                {{ $t("generate_pos_checkout") }}
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="text-h5">{{
                $t("generate_pos_checkout_confirmation_message")
              }}</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red darken-1"
                  text
                  @click="closeForm"
                  >{{ $t("cancel") }}</v-btn
                >
                <v-btn
                  color="primary"
                  text
                  @click="printPOSCheckout()"
                  >{{ $t("yes") }}</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>

        <v-btn
          color="primary"
          @click="getAllProducts()"
          :loading="isLoadingProductsToLDB"
          :disabled="isLoadingProductsToLDB"
        >
          {{ $t("fetch_new_products") }}
        </v-btn>
      </v-toolbar>
    </v-card>
    <v-alert
      v-if="!isOnline"
      type="error"
      dense
      outlined
      class="pa-2 mb-0"
    >
      {{ $t("offline_products_qte_not_sync") }}
    </v-alert>
    <v-alert
      v-if="!user.hub_id"
      type="error"
      dense
      outlined
      class="pa-2 mb-0"
    >
      {{ $t("no_default_hub_for_user") }}
    </v-alert>
    <v-form
      ref="form"
      v-model="is_valid"
      lazy-validation
    >
      <v-card>
        <v-card-text class="pa-0">
          <template v-if="formErrors && !(formErrors.length == 0)">
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-alert
                      type="error"
                      dense
                      outlined
                      class="pa-2 mb-0"
                    >
                      <ul>
                        <li
                          v-for="(err, key) in formErrors"
                          :key="key"
                        >
                          {{ $t(key) }} : {{ err[0] }}
                        </li>
                      </ul>
                    </v-alert>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>

          <template>
            <v-card elevation="5">
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-autocomplete
                      ref="productInput"
                      dense
                      autofocus
                      hide-details
                      outlined
                      :label="$t('name-offline')"
                      v-model="orderProductToAdd.barcode"
                      :items="listLocalProducts"
                      item-text="name"
                      item-value="barcode"
                      :search-input.sync="search_product_input"
                      @keyup.enter="addProductByEnterKey(orderProductToAdd)"
                      :menu-props="{ top: true, offsetY: true, maxHeight: 200 }"
                      :disabled="isLoadingProductsToLDB"
                      :loading="isLoadingProductsToLDB"
                    ></v-autocomplete>
                    <!-- <v-autocomplete v-else ref="productInput" dense autofocus hide-details outlined :label="$t('name')" v-model="orderProductToAdd.barcode" :items="listProducts" item-text="name" item-value="barcode" :search-input.sync="search_product_input" :loading="isLoadingProducts" no-filter @keyup.enter="addProductByEnterKey(orderProductToAdd)" :menu-props="{ top: true, offsetY: true }"></v-autocomplete> -->
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <v-text-field
                      dense
                      hide-details="auto"
                      outlined
                      :label="$t('quantity')"
                      type="number"
                      v-model="orderProductToAdd.qte_in_order"
                      :rules="[rules.quantity]"
                      tabindex="-1"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="2"
                    md="2"
                  >
                    <v-btn
                      block
                      outlined
                      color="primary"
                      @click="addProduct(orderProductToAdd)"
                      :disabled="!is_valid"
                      tabindex="-1"
                      >{{ $t("validate") }}</v-btn
                    >
                  </v-col>

                  <v-col cols="12">
                    <div v-if="orderProducts">
                      <v-data-table
                        :headers="orderProductsHeaders"
                        :items="orderProducts"
                        :expanded="orderProducts"
                        hide-default-footer
                        dense
                        :items-per-page="-1"
                        :single-expand="true"
                        show-expand
                        item-key="total"
                      >
                        <template v-slot:item.data-table-expand="{}">
                        </template>
                        <template v-slot:expanded-item="{ headers, item }">
                          <td
                            :colspan="headers.length"
                            v-if="
                              item?.qte_in_order >
                                item?.discount?.quantity_in_promo &&
                              item?.qte_in_order <= item?.discount?.qte_in_hub
                            "
                          >
                            <h4 class="my-2">
                              {{
                                $t("promotions.qte_sup", {
                                  promoQte: item?.discount?.quantity_in_promo,
                                  remain:
                                    item?.qte_in_order -
                                    item?.discount?.quantity_in_promo,
                                })
                              }}
                            </h4>
                            <p class="mb-0">
                              {{ $t("promotions.total_discount_amount") }}
                              <strong>{{ calcProductDiscount(item) }}</strong>
                            </p>
                            <v-list
                              two-lines
                              flat
                            >
                              <v-list-item-content
                                v-for="lot in item?.discount?.lots"
                                :key="lot.id"
                              >
                                <v-list-item-title
                                  ><strong>
                                    {{ lot?.lot_number }}</strong
                                  ></v-list-item-title
                                >
                                <v-list-item-subtitle>{{
                                  $t("promotions.lot", {
                                    qte_in_lot: lot?.qte_in_lot,
                                    expiration_date:
                                      formatDate(lot?.expiration_date) ?? "/  ",
                                  })
                                }}</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list>
                            <p>
                              {{
                                $t("promotions.quantities", {
                                  in_hub: item?.discount?.qte_in_hub,
                                  in_promo: item?.discount?.quantity_in_promo,
                                })
                              }}
                            </p>
                            <div class="text-center">
                              <v-btn
                                class="mb-3"
                                outlined
                                color="primary"
                                @click="expload(item)"
                                >{{ $t("validate") }}</v-btn
                              >
                            </div>
                          </td>
                          <td
                            :colspan="headers.length"
                            v-else-if="item?.qte_in_order > 0 && item?.discount"
                          >
                            <p class="my-2">
                              {{ $t("promotions.total_discount_amount") }}
                              <strong>{{ calcProductDiscount(item) }}</strong>
                            </p>
                          </td>
                        </template>
                        <template v-slot:item.qte_in_order="{ item }">
                          <v-text-field
                            class="my-1"
                            hide-details="auto"
                            outlined
                            dense
                            type="number"
                            v-model="item.qte_in_order"
                            :rules="[
                              rules.min_quantity(0),
                              rules.max_quantity(
                                item?.discount?.qte_in_hub ?? item.qte_in_hub
                              ),
                            ]"
                            tabindex="-1"
                          ></v-text-field>
                          <!-- ,rules.max_quantity(item.qte_in_hub) !-->
                        </template>
                        <template v-slot:item.discount="{ item }">
                          <v-autocomplete
                            auto-select-first
                            allow-overflow
                            v-if="item?.productPromotions?.length > 0"
                            return-object
                            v-model="item.discount"
                            :items="item.productPromotions"
                            item-text="name"
                            outlined
                            dense
                            hide-details="auto"
                            :placeholder="$t('promotions.select')"
                          >
                            <template v-slot:selection="{ item }">
                              <span v-text="item.discount"></span>
                            </template>
                            <template v-slot:item="{ item }">
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ item.name }} ({{ item.discount }}%)
                                </v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                          <v-text-field
                            v-else
                            dense
                            hide-details="auto"
                            outlined
                            type="number"
                            v-model="item.discount"
                            disabled
                            :rules="[rules.max_quantity(100)]"
                          ></v-text-field>
                        </template>
                        <template v-slot:item.total="{ item }">
                          {{ Math.round(calcProductPrice(item) * 100) / 100 }}
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <v-icon
                            color="red"
                            @click="removeProduct(item)"
                            tabindex="-1"
                          >
                            mdi-close
                          </v-icon>
                        </template>
                        <template v-slot:no-data>
                          {{ $t("empty") }}
                        </template>
                      </v-data-table>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
          <template>
            <!-- payment methods -->
            <v-card elevation="5">
              <v-card-text>
                <v-row>
                  <v-col
                    cols="3"
                    class="align-self-center"
                  >
                    {{ $t("payment_methods") }}
                  </v-col>
                  <v-col
                    cols="3"
                    class="align-self-center"
                    v-for="item in payment_methods"
                    :key="item.id"
                  >
                    <v-btn
                      text
                      :color="item.selected ? 'primary' : ''"
                      @click="selectPaymentMethod(item)"
                    >
                      {{ $t(item?.name) }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <!--end payment methods -->
            <v-card elevation="5">
              <v-card-text>
                <v-row>
                  <v-col
                    cols="6"
                    class="align-self-center"
                  >
                    {{ $t("reduction") }}
                  </v-col>
                  <v-col
                    class="text-right"
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      dense
                      hide-details
                      outlined
                      disabled
                      type="number"
                      v-model="editedOrder.reduction"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <hr class="my-3" />
                <!-- cash payment -->
                <template v-if="editedOrder?.payment_method === 'cash'">
                  <v-row class="mr-4">
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      {{ $t("stamp") }}
                    </v-col>
                    <v-col
                      class="text-right"
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      {{ stamp }} DZD
                    </v-col>
                  </v-row>
                  <hr class="my-3" />
                </template>
                <!-- check payment -->
                <template v-if="editedOrder?.payment_method === 'check'">
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      class="pr-1"
                    >
                      <v-text-field
                        dense
                        hide-details
                        outlined
                        type="text"
                        :label="$t('bank.issuer')"
                        v-model="editedOrder.payment_details.issuer"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      class="px-1"
                    >
                      <v-text-field
                        dense
                        hide-details
                        outlined
                        :label="$t('bank.recipient')"
                        type="text"
                        v-model="editedOrder.payment_details.recipient"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      class="px-1"
                    >
                      <v-text-field
                        dense
                        hide-details
                        outlined
                        :label="$t('bank.rib')"
                        type="number"
                        v-model="editedOrder.payment_details.rib"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      md="3"
                      class="d-flex pl-1"
                    >
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            dense
                            hide-details
                            outlined
                            v-model="editedOrder.payment_details.date"
                            :label="$t('bank.date')"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            class="pr-2"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editedOrder.payment_details.date"
                          no-title
                          color="primary lighten-1"
                          header-color="primary lighten-1"
                          @input="menu = false"
                        ></v-date-picker>
                      </v-menu>

                      <v-text-field
                        dense
                        hide-details
                        outlined
                        :label="$t('bank.amount')"
                        type="number"
                        min="0"
                        v-model="checkAmount"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <hr class="my-3" />
                </template>
                <!-- card payment -->
                <template v-if="editedOrder?.payment_method === 'card'">
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      class="pr-1"
                    >
                      <v-text-field
                        dense
                        hide-details
                        outlined
                        type="text"
                        :label="$t('bank.transaction')"
                        v-model="editedOrder.payment_details.transaction"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </template>

                <v-row class="mr-4">
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    {{ $t("total") }}
                  </v-col>
                  <v-col
                    class="text-right"
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    {{ totalPrice }} DZD
                  </v-col>
                </v-row>
                <!-- cash payment -->
                <template v-if="editedOrder?.payment_method === 'cash'">
                  <hr class="my-3" />
                  <v-row>
                    <v-col
                      cols="6"
                      class="align-self-center"
                    >
                      {{ $t("amount_received") }}
                    </v-col>
                    <v-col
                      class="text-right"
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        id="amount_received_input"
                        dense
                        hide-details
                        outlined
                        type="number"
                        v-model="editedOrder.amount_received"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <hr class="my-3" />
                  <v-row class="mr-4">
                    <v-col cols="6">
                      {{ $t("amount_returned") }}
                    </v-col>
                    <v-col
                      class="text-right"
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      {{
                        (editedOrder.amount_received - totalPrice &&
                          (editedOrder.amount_received - totalPrice).toFixed(
                            2
                          )) ||
                        "-"
                      }}
                      DZD
                    </v-col>
                  </v-row>
                </template>
              </v-card-text>
            </v-card>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="clearBtn"
            color="red darken-1"
            text
            @click="clear()"
            tabindex="-1"
          >
            {{ $t("clear") }}
          </v-btn>
          <v-btn
            id="saveBtn"
            color="primary"
            text
            @click="saveForm(editedOrder)"
            :disabled="!is_valid || is_saving"
            :loading="is_saving"
          >
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <invoice-a7
      ref="invoiceA7"
      :order="posOrder"
    ></invoice-a7>
  </v-container>
</template>

<script>
import { validationRules } from "@/mixins/validationRules";
import { network } from "@/mixins/network";

import { mapGetters } from "vuex";
import _ from "lodash";
import Localbase from "localbase";
import Vue from "vue";
import dbService from "@/store/services/localdb-service";
import productsService from "@/store/services/product-service";

let db = new Localbase("db");
db.config.debug = false;

export default {
  mixins: [validationRules, network],

  mounted() {
    db.collection("products")
      .get()
      .then(async (products) => {
        if (!_.isEmpty(products) && this.isSyncToday())
          this.listLocalProducts = products;
        else {
          this.getAllProducts();
        }
      });

    setInterval(() => {
      db.collection("products")
        .get()
        .then((doc) => {
          this.listLocalProducts = doc;
        });
    }, 100 * 1000);

    document.addEventListener("keypress", function (e) {
      if (e.code == "NumpadAdd" || e.code == "Equal") {
        document.getElementById("saveBtn").focus();
      }
      if (e.code == "NumpadSubtract") {
        document.getElementById("amount_received_input").focus();
      }
      // if(e.code == 'NumpadSubtract'){
      //     document.getElementById('clearBtn').focus()
      // }
    });

    // var params = { currentPage: 1, perPage: 100 };
    // setInterval(() => {
    //   if (this.isOnline) this.getProductsToLocal(params);
    // }, 120 * 1000);

    setInterval(async () => {
      await this.$store.dispatch("sync/syncOrders");
    }, 5 * 60 * 1000);
  },

  components: {
    invoiceA7: () => import("../../pdf/pos/invoiceA7.vue"),
  },

  computed: {
    ...mapGetters({
      isLoadingProducts: "products/isLoadingProducts",
      listProducts: "products/listProducts",
      productsMeta: "products/meta",
      ordersHubId: "orders/getHubID",
      user: "getUser",
    }),

    editedOrder: {
      get() {
        return (
          this.$store.state.orders.editedOrder ?? { hub_id: this.user?.hub_id }
        );
      },
      set(value) {
        this.$store.commit("orders/EDITED_ORDER", value);
      },
    },
    orderProducts: {
      get() {
        return this.$store.state.orders.editedOrder.products || [];
      },
      set(value) {
        this.$store.commit("orders/EDITED_PRODUCTS", value);
      },
    },

    totalItemsPrice() {
      const sum = this.orderProducts
        .map((item) => {
          return this.calcProductPrice(item);
        })
        .reduce((a, b) => a + b, 0);
      return Math.round(sum * 100) / 100;
    },

    totalItemsDiscount() {
      const sum = this.orderProducts
        .map((item) => {
          return this.calcProductDiscount(item);
        })
        .reduce((a, b) => a + b, 0);
      return Math.round(sum * 100) / 100;
    },

    stamp() {
      if (this.editedOrder?.payment_method !== "cash") return 0;
      return this.totalItemsPrice < 500
        ? 0
        : Math.min(10000, Math.round(this.totalItemsPrice) / 100);
    },
    checkAmount: {
      get() {
        return (
          this.editedOrder?.payment_details?.amount ?? this.totalPrice ?? 0
        );
      },
      set(value) {
        this.editedOrder.payment_details.amount = value;
      },
    },
    totalPrice() {
      return (
        Math.round(
          (this.totalItemsPrice +
            (this.payment_method === "cash" ? this.stamp : 0)) *
            100
        ) / 100
      );
    },

    is_new() {
      return this.editedOrder.id ? false : true;
    },

    orderProductsHeaders() {
      return [
        {
          text: this.$t("name"),
          align: "start",
          sortable: false,
          value: "name",
          width: "30%",
        },

        {
          text: this.$t("price_ht"),
          value: "order_price_ht",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("tva"),
          value: "order_tva",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("quantity"),
          value: "qte_in_order",
          align: "center",
          sortable: false,
          width: "15%",
        },
        {
          text: this.$t("discount_%"),
          value: "discount",
          align: "center",
          sortable: false,
          width: "15%",
        },
        {
          text: this.$t("total"),
          value: "total",
          align: "center",
          sortable: false,
          width: "15%",
        },
        {
          text: this.$t("actions"),
          value: "actions",
          align: "center",
          sortable: false,
          width: "10%",
        },
      ];
    },
  },

  watch: {
    totalItemsDiscount: function (value) {
      Vue.set(this.editedOrder, "reduction", value);
    },
  },

  data() {
    return {
      isLoadingProductsToLDB: false,
      posOrder: {},
      listLocalProducts: [],
      isVisibleCheckoutDialog: false,
      is_loading_dn: false,
      is_saving: false,
      show_created_at_picker: null,
      search_product_input: "",
      is_valid: true,
      formErrors: [],
      orderProductToAdd: {
        qte_in_order: 1,
        discount: 0,
      },
      payment_methods: [
        { id: 1, name: "cash", selected: true },
        { id: 2, name: "card", selected: false },
        { id: 3, name: "check", selected: false },
      ],
      menu: false,
    };
  },

  methods: {
    selectPaymentMethod(item) {
      this.payment_methods.forEach((i) => (i.selected = false));
      item.selected = true;
      this.editedOrder.payment_method = item.name;
    },
    expload(item) {
      const index = this.orderProducts.indexOf(item);
      if (index > -1) {
        let item_without_discount = Object.assign({}, item);
        item_without_discount.discount = 0;
        item_without_discount.productPromotions = [];
        item_without_discount.qte_in_order =
          item.qte_in_order - item.discount.quantity_in_promo;
        item_without_discount.total = this.calcProductPrice(
          item_without_discount
        );
        item_without_discount.is_fictif = true;
        item.qte_in_order = item.discount.quantity_in_promo;
        item.total = this.calcProductPrice(item);
        item.qte_without_promo = item_without_discount.qte_in_order;
        this.orderProducts.splice(index + 1, 0, item_without_discount);
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day.substring(0, 2)}/${month}/${year}`;
    },
    clear() {
      this.$store.commit("orders/EDITED_ORDER", {
        reduction: 0,
        payment_method: this.editedOrder.payment_method,
        payment_details: {},
        type: "default",
      });
      this.$refs.productInput.focus();
    },

    async saveForm() {
      if (this.$refs.form.validate()) {
        try {
          if (
            !this.editedOrder.products ||
            this.editedOrder.products.length == 0
          ) {
            this.$store.dispatch("alerts/error", "Products are required");
            this.is_saving = false;
            return;
          }
          this.editedOrder.products.map((product) => {
            if (product.qte_in_order > product?.discount?.quantity_in_promo) {
              this.expload(product);
            }
          });
          this.is_saving = true;
          this.posOrder = { ...this.editedOrder };
          Vue.set(this, "posOrder", { ...this.editedOrder });
          Vue.set(
            this.posOrder,
            "tracking_number_ext",
            "YI-P-" + new Date().valueOf()
          );
          Vue.set(this.posOrder, "stamp_amount", this.stamp);
          Vue.set(this.posOrder, "ttc", this.totalItemsPrice);
          Vue.set(this.posOrder, "total", this.totalPrice);
          Vue.set(this.posOrder, "created_at", new Date().toISOString());

          setTimeout(() => {
            this.$refs.invoiceA7.generateReport();
          }, 300);
          let order = Object.assign({}, this.posOrder);
          order.products = this.posOrder.products
            ?.filter((item) => !item?.is_fictif)
            .map((item) => {
              if (item?.discount?.id) {
                item.promotion_id = item?.discount?.id;
                item.discount_amount = item?.discount?.discount;
              }
              delete item.productPromotions;
              delete item.discount;
              return item;
            });
          await this.storePos(order);
          this.$store.dispatch(
            "alerts/success",
            this.$t("created_successfully")
          );
          setTimeout(() => {
            this.clear();
          }, 1000);
          this.is_saving = false;
        } catch (error) {
          this.is_saving = false;
          this.$store.dispatch("alerts/error", error);
          this.formErrors = error.response?.data?.errors;
        }
      }
    },

    async addProduct(orderProduct) {
      if (this.$refs.form.validate()) {
        if (orderProduct.barcode) {
          db.collection("products")
            .doc(orderProduct.barcode)
            .get()
            .then(async (product) => {
              if (product) {
                const oProduct = this.orderProducts.find(
                  (item) => item.id == product.id
                );

                if (oProduct) {
                  oProduct.qte_in_order =
                    Number(oProduct.qte_in_order) +
                    Number(orderProduct.qte_in_order);
                } else {
                  orderProduct.id = product.id;
                  orderProduct.name = product.name;
                  orderProduct.order_price_ht = parseFloat(product.price_ht);
                  orderProduct.order_tva = product.tva;
                  orderProduct.purchase_price_ht = product.purchase_price_ht;
                  orderProduct.price = product.price;
                  orderProduct.qte_in_hub = product?.qte_in_hub ?? 1000000;
                  orderProduct.discount = 0;
                  orderProduct.productPromotions = this.isOnline
                    ? await this.fetchDiscountInformation(product.id)
                    : 0;
                  const orderProductsCopy = [...this.orderProducts];
                  orderProductsCopy.push(orderProduct);

                  this.orderProductToAdd = {
                    qte_in_order: 1,
                    discount: 0,
                  };
                  if (product.type !== "simple") {
                    orderProduct.order_price_ht = product.price_ht;
                    //   orderProduct.discount = (
                    //     100 -
                    //     (parseFloat(product.price_ht) /
                    //       orderProduct.order_price_ht) *
                    //       100
                    //   ).toFixed(3);
                  }
                  Vue.set(this, "orderProducts", [
                    ...this.orderProducts,
                    orderProduct,
                  ]);
                }
              } else {
                this.$store.dispatch("alerts/error", "Product not found");
              }
            });
        } else {
          this.$store.dispatch("alerts/error", "Product not found");
        }
      }
    },

    addProductByEnterKey(event) {
      const barcode = event.barcode || this.search_product_input;

      const orderProduct = {
        barcode: barcode,
        qte_in_order: 1,
        discount: 0,
      };

      this.addProduct(orderProduct);
      this.search_product_input = "";
    },

    removeProduct(orderProduct) {
      this.orderProducts.splice(this.orderProducts.indexOf(orderProduct), 1);
    },

    calcProductPrice(order_product) {
      const result =
        this.calcProductPriceHT(order_product) +
        this.calcProductTVA(order_product) -
        this.calcProductDiscount(order_product);
      return Math.round(result * 100) / 100;
    },

    calcProductPriceHT(order_product) {
      return (
        parseFloat(order_product.order_price_ht) * order_product.qte_in_order
      );
    },

    calcProductDiscount(order_product) {
      if (
        !order_product?.discount?.discount ||
        order_product?.discount?.type !== "discount"
      )
        return 0;
      let discountAmount =
        Math.round(
          this.calcProductPriceHT(order_product) *
            parseFloat(order_product?.discount?.discount)
        ) / 100;
      let maxDiscountAmount = order_product?.discount?.max_discount_amount;
      if (maxDiscountAmount > 0) {
        return Math.min(discountAmount, maxDiscountAmount);
      }
      return discountAmount;
    },
    calcProductDiscountWithTva(order_product) {
      const itemDiscount = this.calcProductDiscount(order_product);
      let result =
        itemDiscount + (itemDiscount * order_product.order_tva) / 100;
      return Math.round(result * 100) / 100;
    },
    calcProductTVA(order_product) {
      return (
        Math.round(
          (this.calcProductPriceHT(order_product) -
            this.calcProductDiscount(order_product)) *
            parseFloat(order_product.order_tva)
        ) / 100
      );
    },

    getMainHub(listHubs) {
      return listHubs
        .filter((hub) => {
          return hub.is_main == 1;
        })
        .shift();
    },

    async printInvoice(item) {
      this.index = item.id;
      this.is_loading_dn = true;
      await this.$store
        .dispatch("orders/getPosInvoice", item)
        .then((data) => {
          var blob = new Blob([data], { type: "application/pdf" }); //this make the magic
          var blobURL = URL.createObjectURL(blob);

          const element = document.getElementById("invoice_iframe");
          element?.remove();
          var iframe = document.createElement("iframe"); //load content in an iframe to print later
          document.body.appendChild(iframe);

          iframe.style.display = "none";
          iframe.id = "invoice_iframe";
          iframe.src = blobURL;
          iframe.onload = function () {
            setTimeout(function () {
              iframe.focus();
              iframe.contentWindow.print();
            }, 100);
          };

          this.is_loading_dn = false;
        })
        .catch((error) => {
          this.$store.dispatch("alerts/error", error.response?.statusText);
          this.is_loading_dn = false;
        });
    },

    // print today's order list
    async printPOSCheckout() {
      this.is_loading_dn = true;
      await this.$store.dispatch("sync/syncOrders");
      await this.$store
        .dispatch("orders/getPosCheckoutA7")
        .then((data) => {
          var blob = new Blob([data], { type: "application/pdf" }); //this make the magic
          var blobURL = URL.createObjectURL(blob);

          const element = document.getElementById("invoice_iframe");
          element?.remove();
          var iframe = document.createElement("iframe"); //load content in an iframe to print later
          document.body.appendChild(iframe);

          iframe.style.display = "none";
          iframe.id = "invoice_iframe";
          iframe.src = blobURL;
          iframe.onload = function () {
            setTimeout(function () {
              iframe.focus();
              iframe.contentWindow.print();
            }, 100);
          };

          this.is_loading_dn = false;
        })
        .catch((error) => {
          this.$store.dispatch("alerts/error", error.response?.statusText);
          this.is_loading_dn = false;
        });

      this.closeForm();
    },

    closeForm() {
      this.isVisibleCheckoutDialog = false;
    },

    itemBG(item) {
      if (item?.qte_in_hub < item?.qte_in_order) return "red lighten-5";
      return "";
    },

    getProductsToLocal(params) {
      if (!this.user?.hub_id) {
        this.$store.dispatch(
          "alerts/error",
          "this user does not have a default hub"
        );
        return;
      }
      return this.$store
        .dispatch("products/barodesQuery", {
          hub_id: this.user?.hub_id,
          itemsPerPage: params.perPage,
          page: params.currentPage,
        })
        .then((data) => {
          (params.currentPage =
            (data.meta?.current_page + 1) % data.meta?.last_page ??
            params.currentPage + 1),
            data.products.map(async (item) => {
              await db.collection("products").doc(item.barcode).set({
                id: item.id,
                barcode: item.barcode,
                name: item.name,
                price: item.price,
                price_ht: item.price_ht,
                tva: item.tva,
                qte_in_hub: item.qte_in_hub,
              });
            });
        })
        .catch((error) => {
          this.isLoadingProductsToLDB = false;
          console.log(error);
        });
    },

    async fetchDiscountInformation(product_id) {
      const hub_id = this.user?.hub_id;
      const promotions = await productsService.getDiscounts(product_id, hub_id);
      return promotions;
    },

    timeout(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

    isSyncToday() {
      const day = localStorage.getItem("day");
      const dateNow = new Date();

      if (dateNow.getDate() == day) {
        return true;
      }
      localStorage.setItem("day", dateNow.getDate());
      return false;
    },

    async getAllProducts() {
      this.isLoadingProductsToLDB = true;

      try {
        await db.collection("products").delete();

        const { products } = await this.$store.dispatch(
          "products/barodesQuery",
          {
            hub_id: this.user?.hub_id,
            all: "true",
          }
        );

        const mappedData = products.map((item) => ({
          id: item.id,
          barcode: item.barcode,
          type: item.type,
          name: item.name,
          price: item.price,
          price_ht: item.price_ht,
          purchase_price_ht: item.purchase_price_ht,
          tva: item.tva,
          qte_in_hub: item.qte_in_hub,
          bundle_items: item.bundle_items,
          _key: item.barcode,
        }));

        await db.collection("products").set(mappedData, { keys: true });
        this.listLocalProducts = mappedData;
        this.isLoadingProductsToLDB = false;
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.statusText);
        this.isLoadingProductsToLDB = false;
      }
    },

    async storePos(order) {
      if (this.isOnline) {
        await this.$store.dispatch("orders/addPos", order).catch((error) => {
          db.collection("orders")
            .doc(this.posOrder.tracking_number_ext)
            .set(this.posOrder);

          if (error.code !== "ECONNABORTED") {
            const data = {
              failed: 1,
              error:
                error.response?.data?.errors ?? error.response?.data?.message,
            };
            dbService.updateOrderByTrackingNumber(
              order.tracking_number_ext,
              data
            );
            this.$store
              .dispatch("orders/logErrorPos", order, {
                root: true,
              })
              .then(() => {
                dbService.markOrderAsLogged(order.tracking_number_ext);
              });
          }
        });
      } else {
        db.collection("orders")
          .doc(this.posOrder.tracking_number_ext)
          .set(this.posOrder);
      }
    },
  },
};
</script>
